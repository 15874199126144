
    import { Component, Vue } from "vue-property-decorator";
    import {
    getAuth,
    createUserWithEmailAndPassword
    } from "firebase/auth";

    @Component
    export default class Register extends Vue {
        email: any = "";
        password: any = "";
        errorMessageAlert: any = "";
        showAlert = false;
        startLoading = false;

        registerClick(){
            const auth = getAuth();
            this.startLoading = true;

            createUserWithEmailAndPassword(auth,this.email, this.password)
            .then(() => {
                alert('Successfully registered! Please login.');
                this.$router.push('/login');
            })
            .catch(error => {
                alert(error.message);
            });
        }
    }
