
import { Component, Vue } from "vue-property-decorator";
import { getAuth, signOut, onAuthStateChanged } from "firebase/auth";

@Component
export default class MainPage extends Vue {
  visible: any = true;
  displayName: any = "";
  visibleMenu: any = true;

  get loggedinUsername() {
    return this.$store.state.user.displayName;
  }

  menuTrigger() {
    this.visibleMenu = !this.visibleMenu;
  }

  signoutClicked() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        this.$store.dispatch("isLoggedinState");
        this.$store.dispatch("isLoggedin", "");
        this.$router.replace("/login");
        this.$toasted.show("You have successfully logged out !!", {
          type: "success"
        });
      })
      .catch(error => {
        // An error happened.
      });
  }
}
