
import { Component, Vue } from "vue-property-decorator";
import db from "@/firebase";
import {
  addDoc,
  collection,
  query,
  deleteDoc,
  doc,
  onSnapshot,
  getDoc,
  updateDoc
} from "@firebase/firestore";
@Component
export default class Education extends Vue {
  show: any = false;
  educationList: any = [];
  scoreTypeOptions: any = [
    { text: "-- select score type --", value: "" },
    { text: "CPI", value: "CPI" }
  ];
  education: any = {
    board: "",
    collage: "",
    degree: "",
    score: "",
    scoreType: "",
    year: "",
    active:true,
  };
  updateFlag: any = false;
  rowId: any = null;

  mounted() {
    this.getEducationList();
  }

  resetData(){
    this.education.board="";
    this.education.collage="";
    this.education.degree="";
    this.education.score="";
    this.education.scoreType="";
    this.education.year="";
  };

  addEducation(){
    this.resetData();
    this.updateFlag = false;
    this.$bvModal.show('modal-education');
  }

  // get all data relatime data
  async getEducationList() {
    this.show = true;
    const q = query(collection(db, "education"));
    const unsubscribe = await setTimeout(() => {
      onSnapshot(q, querySnapshot => {
        this.educationList = [];
        querySnapshot.forEach(doc => {
          this.educationList.push({ id: doc.id, ...doc.data() });
        });
        this.show = false;
      });
    }, 3000);
  }

  // submit or update data
  async submitdata() {
    const dataReceived = {
      board:this.education.board,
      collage:this.education.collage,
      degree:this.education.degree,
      score:this.education.score,
      scoreType:this.education.scoreType,
      year:this.education.year,
      active: this.education.active,
    };

    this.$validator.validate().then((result) => {
      if (result) {
        if (this.updateFlag) {
          updateDoc(doc(db, "education", this.rowId), dataReceived)
            .then(() => {
              this.$toasted.show("Education has updated successfully", {
                type: "success",
              });
              this.updateFlag = false;
              this.rowId = null;
            })
            .catch((error) => {
              this.$toasted.show(error, {
                type: "error",
              });
            });
        } else {
          addDoc(collection(db, "education"), dataReceived)
            .then(() => {
              this.$toasted.show("Education has saved successfully", {
                type: "success",
              });
            })
            .catch((error) => {
              this.$toasted.show(error, {
                type: "error",
              });
            });
        }
        this.$bvModal.hide('modal-education')
        this.resetData();
      } else {
        this.$toasted.show("please fill in the data fields.", {
          type: "error",
        });
      }
    });
  }

    // delete data
  async deleteExp(id: any) {
    await deleteDoc(doc(db, "education", id));
  }

  // getting edit form data
  async editExp(id: any) {
    console.log(id)
    const docSnap = await getDoc(doc(db, "education", id));
    this.rowId = id;
    if (docSnap.exists()) {
      this.education.board=docSnap.data().board;
      this.education.collage=docSnap.data().collage;
      this.education.degree=docSnap.data().degree;
      this.education.score=docSnap.data().score;
      this.education.scoreType=docSnap.data().scoreType;
      this.education.year=docSnap.data().year;
      this.updateFlag = true;
      this.$bvModal.show('modal-education')
    }
  }
}
