
import { Component, Vue } from "vue-property-decorator";
import Weather from "@/components/weather/Weather.vue";
@Component({
  components:{
    Weather
  }
})
export default class Navbar extends Vue {
  currentThemeMode: any = this.$store.state.currentTheme;
  mounted() {
    document.documentElement.className = this.currentThemeMode ? "light" : "dark";
  }

  toggleTheme() {
    this.currentThemeMode = !this.currentThemeMode;
    document.documentElement.className = this.currentThemeMode ? "light" : "dark";
    this.$store.dispatch('changedTheme', this.currentThemeMode)
  }
}
