
import { Component, Vue } from 'vue-property-decorator';
import Banner from "@/components/Banner.vue";
import InfoText from "@/components/infoText.vue";
import PortfolioMain from "@/components/PortfolioMain.vue";
import Experience from "@/components/Experience.vue";
import Testimonials from "@/components/Testimonials.vue";
import Education from "@/components/Education.vue";
import Portfolio from "@/components/Portfolio.vue";
import Contact from "@/components/Contact.vue";
import Navbar from '@/components/Navbar.vue';

@Component({
  components: {
    Banner,
    InfoText,
    PortfolioMain,
    Experience,
    Testimonials,
    Education,
    Portfolio,
    Contact,
    Navbar
  }
})
export default class Home extends Vue {
}
