import Vue from "vue";
import App from "./App.vue";
import store from "./store/store";
import router from "./router";
import '@/filter';
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "./assets/scss/app.scss";

import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import VueScreen from "vue-screen";
Vue.use(VueScreen, "bootstrap");

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
Vue.use(VueAwesomeSwiper);

import Toasted from 'vue-toasted';
Vue.use(Toasted,{
  theme: "bubble",
  position: "top-right",
  duration: 3000,
})

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "linear",
  offset:-140,
});

import "animate.css";

import axios from "axios";
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
