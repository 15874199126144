
import { Component, Vue } from "vue-property-decorator";
import db from "@/firebase";
import {
  addDoc,
  collection,
  query,
  deleteDoc,
  doc,
  onSnapshot,
  getDoc,
  updateDoc,
} from "@firebase/firestore";
@Component
export default class AddExperience extends Vue {
  show:any=false;
  companyName: any = "";
  positionName: any = "";
  year: any = "";
  description: any = "";
  technologyList: any = [
    { text: "xd", image: require('@/assets/images/xd.png'), value: "xd" },
    { text: "wordpress", image: require('@/assets/images/wordpress.png'), value: "wordpress" },
    { text: "bootstrap", image: require('@/assets/images/bootstrap.png'), value: "bootstrap" },
    { text: "vue-bootstrap", image: require('@/assets/images/vue-bootstrap.png'), value: "vue-bootstrap" },
    { text: "vue", image: require('@/assets/images/vue.png'), value: "vue" },
    { text: "typescript", image: require('@/assets/images/ts.png'), value: "typescript" },
    { text: "sharepoint", image: require('@/assets/images/sharepoint.png'), value: "sharepoint" },
    { text: "scss", image: require('@/assets/images/scss.png'), value: "scss" },
    { text: "php", image: require('@/assets/images/php.png'), value: "php" },
    { text: "photoshop", image: require('@/assets/images/photoshop.png'), value: "photoshop" },
    { text: "jquery", image: require('@/assets/images/jquert.png'), value: "jquery" },
    { text: "html", image: require('@/assets/images/html.png'), value: "html" },
    { text: "css", image: require('@/assets/images/css.png'), value: "css" },
    { text: "corel", image: require('@/assets/images/corel.png'), value: "corel" },
    { text: "git", image: require('@/assets/images/git.png'), value: "git" },
  ];
  disabled: any = true;
  technologyListSelected: any = [];
  getExperienceList: any = [];
  updateFlag: any = false;
  rowId: any = null;

  mounted() {
    this.getDocumentsOnSnapshot();
  }

  resetData(){
    this.companyName = "";
    this.positionName = "";
    this.year = "";
    this.description = "";
    this.technologyListSelected = [];
  };

  addExperience(){
    this.resetData();
    this.$bvModal.show('modal-experience')
  }

  // submit or update data
  async submitdata() {
    const dataReceived = {
      companyName: this.companyName,
      description: this.description,
      positionName: this.positionName,
      technologies: this.technologyListSelected,
      year: this.year,
      active: true,
    };

    this.$validator.validate().then((result) => {
      if (result) {
        if (this.updateFlag) {
          updateDoc(doc(db, "jobexperience", this.rowId), dataReceived)
            .then(() => {
              this.$toasted.show("Experience has updated successfully", {
                type: "success",
              });
              this.updateFlag = false;
              this.rowId = null;
            })
            .catch((error) => {
              this.$toasted.show(error, {
                type: "error",
              });
            });
        } else {
          addDoc(collection(db, "jobexperience"), dataReceived)
            .then(() => {
              this.$toasted.show("Experience has saved successfully", {
                type: "success",
              });
            })
            .catch((error) => {
              this.$toasted.show(error, {
                type: "error",
              });
            });
        }
        this.$bvModal.hide('modal-experience')
        this.resetData();
      } else {
        this.$toasted.show("please fill in the data fields.", {
          type: "error",
        });
      }
    });
  }

  // get all data relatime data
  async getDocumentsOnSnapshot() {
    this.show = true;
    const q = query(collection(db, "jobexperience"));
    const unsubscribe = await setTimeout(() => {
      onSnapshot(q, (querySnapshot) => {
      this.getExperienceList = [];
      querySnapshot.forEach((doc) => {
        this.getExperienceList.push({ id: doc.id, ...doc.data() });
      });
      this.show = false;
    });
    }, 3000);
        
  }

  // delete data
  async deleteExp(id: any) {
    await deleteDoc(doc(db, "jobexperience", id));
  }

  // getting edit form data
  async editExp(id: any) {
    const docSnap = await getDoc(doc(db, "jobexperience", id));
    this.rowId = id;
    if (docSnap.exists()) {
      this.companyName = docSnap.data().companyName;
      this.positionName = docSnap.data().positionName;
      this.year = docSnap.data().year;
      this.description = docSnap.data().description;
      this.technologyListSelected = docSnap.data().technologies;
      this.updateFlag = true;
      this.$bvModal.show('modal-experience')
    }
  }
}
