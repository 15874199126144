import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const config = {
  apiKey: "AIzaSyDDHCQRJjO42RRdX0zOdalcescePUC5YZc",
  authDomain: "sumeetpatelportfolio.firebaseapp.com",
  projectId: "sumeetpatelportfolio",
  storageBucket: "sumeetpatelportfolio.appspot.com",
  messagingSenderId: "69338191970",
  appId: "1:69338191970:web:4f9c2447a864bfd43a193b",
  measurementId: "G-0RDLN6JX0S",
};

const firebaseApp = initializeApp(config);

// firebase.getCurrentUser = () => {
//   return new Promise((resolve, reject) => {
//       const unsubscribe = firebase.auth().onAuthStateChanged(user => {
//           unsubscribe();
//           resolve(user);
//       }, reject);
//   })
// };

const db = getFirestore();

export default db;
