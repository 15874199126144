
import { Component, Vue } from "vue-property-decorator";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import store from '../store/store';
@Component
export default class Login extends Vue {
  email: any = "sumeetpatelofficial@gmail.com";
  password: any = "Sam@12108";
  errorMessageAlert: any = "";
  showAlert = false;
  startLoading = false;
  //sumeetpatelofficial@gmail.com
  //Sam@12108

  loginClick() {
    const auth = getAuth();
    this.startLoading = true;
    signInWithEmailAndPassword(auth, this.email, this.password)
      .then((userCredential) => {        
        const userFirebase = userCredential.user;        
        if (userFirebase) {
          const setStoreUser = {
            uid : userFirebase.uid,
            email : userFirebase.email,
            displayName : userFirebase.displayName,
            phoneNumber : userFirebase.phoneNumber,
            photoUrl : userFirebase.photoURL,
            emailVerified:userFirebase.emailVerified
          }
          this.$store.dispatch("isLoggedin", setStoreUser);
          this.$store.dispatch('isLoggedinState')
          this.$router.replace({path:"/profile"});
        } else {
          this.$store.commit("isLoggedin", null);
        }
        this.startLoading = false;
      })
      .catch((error) => {
        setTimeout(() => {
          this.startLoading = false;
        }, 500);
        const errorMessage = error.message;
        this.showAlert = true;
        this.errorMessageAlert = errorMessage;
      });
  }

  mounted() {
    this.checkUserStat();
  }

  checkUserStat() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.$router.replace("/profile");
      }
    });
  }
}
