
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Weather extends Vue {
  weatherInfo: any = "";
  currentLocation: any = "";
  setLoading: any = false;
  latitude:any="";
  longitude:any="";
  errorText:any=""
  created() {
    this.getCurrentLocation();
  }

  getCurrentLocation() {
    this.setLoading = true;
    if (navigator.geolocation) {
      // console.log("getCurrentLocation called");
      return navigator.geolocation.getCurrentPosition(
        this.getCityFromPosition,
        this.showError
      );
    }
  }

  async getCityFromPosition(res: any) {
    // console.log("getCityFromPosition called");
    this.latitude = res.coords.latitude;
    this.longitude = res.coords.longitude;
    await this.axios
      .get(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${this.latitude}&longitude=${this.longitude}&localityLanguage=en`
      )
      .then(res => {
        this.currentLocation = `${res.data.city},${res.data.countryName}`;
        this.getCurrentWeatherDetail(this.currentLocation);
      });
  }

  async getCurrentWeatherDetail(location: any) {
    // console.log("getCurrentWeatherDetail called")
    await setTimeout(() => {
      const weatherUrl = `https://api.openweathermap.org/data/2.5/weather?q=${location}&units=metric&appid=eaf1577e4ffc0d349daecafaf7c9fe87`;
      this.axios.get(weatherUrl).then(res => {
        this.setLoading = false;
        const data = res.data;
        // console.log(data);
        const { temp, humidity, pressure } = data.main;
        const { description: weathermood, main } = data.weather[0];
        const { icon } = data.weather[0];
        const { name } = data;
        const { speed } = data.wind;
        const { country, sunset } = data.sys;

        this.weatherInfo = {
          temp,
          humidity,
          pressure,
          weathermood,
          name,
          main,
          icon,
          speed,
          country,
          sunset
        };
      });
    }, 2000);
  }

  showError(error: any) {
    this.setLoading = false;
    switch (error.code) {
      case error.PERMISSION_DENIED:
        this.errorText = "User denied the request for Geolocation.";
        break;
      case error.POSITION_UNAVAILABLE:
        this.errorText = "Location information is unavailable.";
        break;
      case error.TIMEOUT:
        this.errorText = "The request to get user location timed out.";
        break;
      case error.UNKNOWN_ERROR:
        this.errorText = "An unknown error occurred.";
        break;
    }
  }

  getHourlyData(){
    this.axios
    .get(`https://api.openweathermap.org/data/2.5/forecast?lat=${this.latitude}&lon=${this.longitude}&appid=eaf1577e4ffc0d349daecafaf7c9fe87`)
    .then(
      (res)=>{
        console.log(res.data)
      }
    )
  }
}
