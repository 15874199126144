
import { Component, Vue } from "vue-property-decorator";
import Testimonials from "@/components/Testimonials.vue";

@Component({
  components: {
    Testimonials
  }
})
export default class PorfolioMain extends Vue {
  profileImage: any = require("@/assets/images/profile.png");
  timeline: any = [
    {
      year: "1989",
      value: "Born in Vadodara, Gujarat, India"
    },
    {
      year: "2003",
      value: "cleared SSC secondary school from Gujarat Board (GSEB)."
    },
    {
      year: "2005",
      value: "Passed HSC Higher secondary school from Gujarat Board (GSEB)."
    },
    {
      year: "2009",
      value:
        "Joined a collage in Vidhyanagar to pursue the bachelor of computer application."
    },
    {
      year: "April 2012",
      value: "Graduated with good grades of bachelor degree (BCA)"
    },
    {
      year: "Aug 2012",
      value:
        "Joined a collage in Zadeshwer to pursue the masters of computer application."
    },
    {
      year: "March 2015",
      value: "Graduated with distinctions of masters degree (MCA)"
    },
    {
      year: "March 2015",
      value:
        "Started the journey and became a web designer  in company of three called Grace and Bless Infotech."
    },
    {
      year: "July 2016",
      value: "Started my new job as a UI Developer at Sanah Infosolutions. "
    },
    {
      year: "Oct 2017",
      value:
        "Started my new job as a UI Developer at Collabera Technologies Pvt Ltd. "
    },
    {
      year: "March 2019",
      value: "Started my new job as a UI Developer at Darshan Softech Pvt Ltd."
    },
    {
      year: "March 2020",
      value:
        "Started my new job as a Mid UI Developer at eRoam Technologies Pvt Ltd."
    },
    {
      year: "Nov 2022",
      value:
        "Started my new job as a Software Developer - Frontend at Tech Mahindra."
    }
  ];

  portfolioList:any=[
    {
      type:'website',
      imagePath:'vue-project2',
      name:'User Onboarding project', 
      uri:''
    },
    {
      type:'website',
      imagePath:'stuarts',
      name:'Stuarts Financial Service', 
      uri:''
    },
    {
      type:'website',
      imagePath:'digiExperts',
      name:'Digital Experts', 
      uri:''
    },
    {
      type:'website',
      imagePath:'bhagirath',
      name:'Bhagirath Fincare', 
      uri:''
    },
    {
      type:'website',
      imagePath:'augmentation',
      name:'Augmentation Recruitments', 
      uri:''
    },
    {
      type:'website',
      imagePath:'mindavenue',
      name:'Mind Avenue', 
      uri:''
    }
  ]
}
