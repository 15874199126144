
import { Component, Vue } from "vue-property-decorator";
import { getAuth, sendEmailVerification,  updateProfile } from "firebase/auth";
import {UpdateData,} from "firebase/firestore/lite"
@Component
export default class Profile extends Vue {
  userData:any="";
  name:any=''
  show:any=false;
  // userData:any="";
  currentUser:any = {};

  mounted() {
    this.show=true;
    setTimeout(() => {
      this.userData = this.$store.state.user;
      this.show = false
    }, 2000);    

    const auth = getAuth();
    this.currentUser = auth.currentUser;
  }

  updateData() {   
    debugger;     
    updateProfile(this.currentUser, {
      displayName:this.userData.displayName,
      photoURL:this.userData.photoURL,
    })
    .then(() => {
      const setStoreUser = {
        uid : this.currentUser.uid,
        email : this.currentUser.email,
        displayName : this.currentUser.displayName,
        phoneNumber : this.currentUser.phoneNumber,
        photoUrl : this.currentUser.photoURL,
        emailVerified:this.currentUser.emailVerified
      }
      this.$store.dispatch("isLoggedin", setStoreUser);
      this.$toasted.show("Data updated successfully", {
        type: "success",
      });
    })
    .catch((error) => {
      this.$toasted.show(error.message, {
        type: "error",
      });
    });
  }

  verifyEmail(){
    const auth = getAuth();
    this.currentUser = auth.currentUser;
    sendEmailVerification(this.currentUser)
    .then(() => {
      // Email verification sent!
      this.$toasted.show("Verification email has been sent.", {
        type: "info",
      });
    });
  }

  updateUserPhoneNumber(){
    const auth = getAuth();
    this.currentUser = auth.currentUser;

    // updatePhoneNumber
  }

  logoChange(e: { target: { files: any[] } }, value: any) {
    const file = e.target.files[0];
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|)$/i;
    if (!allowedExtensions.exec(file.name)) {
      this.$toasted.show(
        "Please upload file having extensions .jpeg/.jpg/.png only.",
        {
          theme: "bubble",
          position: "top-right",
          duration: 5000,
          type: "fail",
        }
      );
      return false;
    } else {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e1: any) => {
          if (value == "logo") {
            this.userData.profileImage = e1.target.result;
          }
        };
        reader.readAsDataURL(file);
      }
    }
  }

  removeLogoImage(value: any) {
    if (value == "logo") {
      this.userData.profileImage = "";
    }
  }
}
