import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "../store/store";
import Home from "../views/Home.vue";
import addExperience from "../components/useradmin/addExperience.vue";
import profile from "../components/useradmin/profile.vue";
import changePassword from "../components/useradmin/changePassword.vue";
import education from "../components/useradmin/education.vue";
import dashboard from "../components/useradmin/dashboard.vue";
import mainPage from "../components/useradmin/mainPage.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/admin",
    name: "admin",
    component: mainPage,
    redirect:"profile",
    children:[      
      {
        path: "/experience",
        name: "experience",
        component: addExperience,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/profile",
        name: "my profile",
        component: profile,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/password",
        name: "change password",
        component: changePassword,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/education",
        name: "Education",
        component: education,
        meta: {
          requiresAuth: true
        },
      },
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to,from,next)=>{
//   if(to.meta.requiresAuth && store.state.user.uid === ''){
//     next({
//       path: 'login',
//       replace:true,
//       query:{ redirect: to.fullPath }
//     })
//   } else{
//     next()
//   }
// })


export default router;
