
import { Component, Vue } from "vue-property-decorator";
import SectionHeading from '@/components/subcomponent/SectionHeading.vue';

@Component({
    components:{
        SectionHeading
    }
})
export default class Education extends Vue {
  educationDetail:any=[
    {
      id:1,
      year:'2012-2015',
      degree:'Masters of Computer Application (MCA)',
      collage:'NCCA',
      board:'Gujarat Technology University (GTU)',
      scoreType:'CPI',
      score:'7.28'
    },
    {
      id:2,
      year:'2009-2012',
      degree:'Bachelors of Computer Application (BCA)',
      collage:'Sardar Patel Education Campus',
      board:'Sardar Patel University (SPU)',
      scoreType:'',
      score:'58%'
    },
    {
      id:3,
      year:'2007',
      degree:'HSC',
      collage:'Lal Bahadur Shahtri Vidhyalaya',
      board:'Science - GSEB',
      scoreType:'',
      score:'51%'
    },
    {
      id:4,
      year:'2005',
      degree:'SSC',
      collage:'Mahatma Ghandhi Vidhyalaya',
      board:'GSEB',
      scoreType:'',
      score:'56%'
    },
  ]
}
