
    import { Component, Vue } from 'vue-property-decorator';
    import SectionHeading from '@/components/subcomponent/SectionHeading.vue';
    import TestimonialSlide from '@/components/subcomponent/TestimonialSlide.vue';
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

    @Component({
        components:{
            SectionHeading,
            TestimonialSlide,
            Swiper,
            SwiperSlide
        }
    })  

    export default class Testimonials extends Vue {

        swiperComponentOption:any = {
            loop: false,
            slidesPerView: 1,
            navigation: {
                nextEl: ".slide-next",
                prevEl: ".slide-prev"
            }
        }
        
        testimonialSlide:any=[
            {
                textContent:'Sumeet is very enthusiastic by nature and always keen to learn new technologies. I have worked with him for approx 8 month and found him very productive and innovative all the time. Along with Graphic and Designing he is also very good at Client side scripting as well. Wish him best for his future.',
                testimoni:'Govind Bhatu'
            },
            {
                textContent:'I had working with Sumeet and I found him very visionary, creative, a quick learner and a great asset to the team. I would recommend him for his great UI Development & Graphic Design skill and amazing problem solving skill. He knew very well where developer get ease to code on his design.',
                testimoni:'Vinit Shah'
            },
            {
                textContent:'Sumeet is a guy who thinks about out of the box things and the great thing about it is that, he actually brings those into outcome. He has mastered himself in designing aspects and has an immense dedication in learning new technologies and platform. He is a very good team player, always there to help the people when needed.',
                testimoni:'Neel Patel'
            },
            {
                textContent:'We both worked in different teams but whenever we worked on any project, my experience was amazing. So experimental mindset, self-taught, never give-up attitude and smart work, was few of his uncountable skillsets. I know, he has lot more to do and definitely he will achieve. God bless Sumeet, Cheers.',
                testimoni:'Satish Dubey'
            },
        ]
    }
