
    import { Component, Vue } from 'vue-property-decorator';
    import SectionHeading from '@/components/subcomponent/SectionHeading.vue';

    @Component({
        components:{
            SectionHeading
        }
    })
    export default class Portfolio extends Vue {
        
    }
