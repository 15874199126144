import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default new Vuex.Store({
  state: {
    currentTheme:true,
    isLoggedin: false,
    user:""
  },
  mutations: {
    LOGGEDIN_USER(state, payload) {
      Vue.set(state, "user", payload)
    },
    IS_LOGGEDIN_STATE(state) {
      Vue.set(state, "isLoggedin", !state.isLoggedin);
    },
    CHANGED_THEME_MUTATION(state:any, value){
      Vue.set(state,'currentTheme',value)
    }
  },
  actions: {
    isLoggedin({ commit }, payload) {
      commit("LOGGEDIN_USER", payload);
    },
    isLoggedinState({ commit }) {
      commit("IS_LOGGEDIN_STATE");
    },
    changedTheme({commit}, payload){
      commit('CHANGED_THEME_MUTATION', payload)
    }
  },
  getters:{
    user: (state: any) => state.user,
    currentTheme: (state: any) => state.currentTheme,
  },
  plugins:[vuexLocal.plugin]
});
